<template>
    <div>
        <ts-page-title
            :title="$t('monitoringQuestion.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('monitoringQuestion.pageTitle'),
                    active: true
                }
            ]"
        />
        <div class="row">
            <ts-loading-banner :loading="loading">
                <div class="col-md-12">
                    <ts-panel>
                        <ts-panel-wrapper>
                            <question-info
                                ref="question_info"
                                v-model="model"
                            />
                            <div class="row">
                                <div
                                    class="col-md-12 tw-text-right tw-space-x-2"
                                >
                                    <ts-button
                                        @click="
                                            $router.push({
                                                name: 'monitoring-question'
                                            })
                                        "
                                    >
                                        {{ $t("cancel") }}</ts-button
                                    >
                                    <ts-button
                                        :waiting="saving"
                                        color="primary"
                                        @click.prevent="update"
                                    >
                                        {{ $t("update") }}</ts-button
                                    >
                                </div>
                            </div>
                        </ts-panel-wrapper>
                    </ts-panel>
                </div>
            </ts-loading-banner>
        </div>
    </div>
</template>
<script>
import { Errors } from "form-backend-validation";
import { isEmpty } from "lodash";
import { mapState } from "vuex/dist/vuex.common.js";
import QuestionInfo from "./question-info.vue";
export default {
    name: "monitoringQuestionUpdate",
    components: {
        QuestionInfo
    },
    data() {
        return {
            loading: false,
            model: {
                answer_format: "Radio",
                question_cat_id: null,
                question_en: null,
                question_kh: null,
                display_order: 0,
                remark: null,
                question_short_kh: null,
                question_short_en: null,
                answer_lists: [
                    {
                        question_id: 0,
                        answer_id: 0,
                        answer_en: null,
                        answer_kh: null,
                        answer_prefix: null,
                        is_enable_comment_box: false
                    }
                ]
            },
            saving: false,
            waiting_save_add_new: false
        };
    },
    computed: {
        ...mapState("creditOperation/monitoringQuestion", ["edit_data"])
    },
    methods: {
        update() {
            this.errors = new Errors();
            this.saving = true;
            this.$store
                .dispatch("creditOperation/monitoringQuestion/update", {
                    id: this.$route.params.id,
                    data: this.model
                })
                .then(response => {
                    this.saving = false;
                    this.notice({ type: "success", text: response.message });
                    this.$router.push({
                        name: "monitoring-question"
                    });
                })
                .catch(error => {
                    this.saving = false;
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                });
        },
        async fetchData() {
            this.loading = true;
            await this.$refs.question_info.getDisplayFormat();
            await this.$refs.question_info.getQuestionCategory();
            if (!isEmpty(this.edit_data)) {
                await this.$store.dispatch(
                    "creditOperation/monitoringQuestion/find",
                    this.$route.params.id
                );
                this.setEditData();
            }
            this.loading = false;
        },
        setEditData() {
            this.$nextTick(() => {
                if (this.$refs.question_kh) {
                    this.$refs.question_kh.focus();
                }
            });
            if (!isEmpty(this.edit_data)) {
                this.model.question_kh = this.edit_data.question_kh;
                this.model.question_en = this.edit_data.question_en;
                this.model.answer_format = this.edit_data.answer_format;
                this.model.question_cat_id = this.edit_data.question_cat_id;
                this.model.display_order = this.edit_data.display_order;
                this.model.remark = this.edit_data.remark;
                this.model.question_short_kh = this.edit_data.question_short_kh;
                this.model.question_short_en = this.edit_data.question_short_en;
                this.model.answer_lists = this.edit_data.answers.map(el => ({
                    question_id: el.question_id,
                    answer_id: el.answer_id,
                    answer_en: el.answer_en,
                    answer_kh: el.answer_kh,
                    answer_prefix: el.answer_prefix,
                    is_enable_comment_box: el.is_enable_comment_box
                }));
            }
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "MONITORING QUESTION ANSWER",
                desc: not.text
            });
        }
    },

    beforeRouteEnter: (to, from, next) => {
        next(vm => {
            vm.fetchData();
        });
    },
    beforeRouteLeave(to, from, next) {
        if (isEmpty(to.params)) {
            this.$store.commit(
                "creditOperation/monitoringQuestion/RESET_STATE"
            );
        }
        next();
    }
};
</script>
